.add_form {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1200;
    width: auto;
}

@media (max-width: 960px) {
    .add_form {
        width: 90vw !important;
        height: 90vh !important;
        overflow-y: auto;
    }
}
.overlay {
    position: fixed;
    bottom: 0;
    height: 100vh;
    width: 100%;
    background-color: #000;
    opacity: 0.6;
    z-index: 1100;
}