.header{
    border-right: 1px solid #fff;
    padding: 10px;
    vertical-align: middle !important;
}
.header_item {
    padding: 10px;
    vertical-align: middle !important;
}

@media screen and (max-width: '800px') {
    .no_mobile {
        display: none;
    }
    .mobile{
        display: block !important;
    }
}
.right_border{
    border-right: 1px solid #000;
    padding: 0 10px;
}
.mobile{
    display: none;
}