.divcol_sm_1218 {
    width: calc(100% - 855.4px);
    height: 18rem;
  }
  .divservices {
    border-radius: 9.12px;
    background-color: #f5f5f1;
    box-shadow: 0 1.8248631954193115px 4.56px #0e4167;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    height: 15rem;
    min-width: 15rem;
  }
  .divservices:hover {
    box-shadow: 0 1.8248631954193115px 4.56px #13698e;
  }

.heading {
    color: #000
}
.icon {
  height: 3rem;
  width: 3rem;
  color: #000;
}

.image{
  margin: auto;
  height: 18rem;
}