.alerts {
    background-color: #e5f0fb;
    height: 18rem;
    line-height: 1.8;
  }
  .title_background {
    background-color: #97c0dd !important;
  }
  .image {
    height: 18rem;
    margin: auto;
  }
  .scroll_container {
      height: 80%;
      overflow: hidden;
    }
  .scrolling_text {
    height: 100%;
    text-align: center;
    -moz-transform: translateY(100%);
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  
    -moz-animation: my-animation 5s linear infinite;
    -webkit-animation: my-animation 5s linear infinite;
    animation: my-animation 6s linear infinite;
  }
  
  @-moz-keyframes my-animation {
    from {
      -moz-transform: translateY(100%);
    }
    to {
      -moz-transform: translateY(-100%);
    }
  }
  
  @-webkit-keyframes my-animation {
    from {
      -webkit-transform: translateY(100%);
    }
    to {
      -webkit-transform: translateY(-100%);
    }
  }
  
  @keyframes my-animation {
    from {
      -moz-transform: translateY(100%);
      -webkit-transform: translateY(100%);
      transform: translateY(100%);
      opacity: 1;
    }
    to {
      -moz-transform: translateY(-100%);
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
      opacity: 0.5;
    }
  }
  