.button_outline_green {
    border: 1px solid #25bdd4 !important;
    background-color: #fff !important;
    color: #25bdd4 !important;
}
.button_outline_green:hover {
    border: 1px solid #25bdd4 !important;
    background-color: #25bdd4 !important;
    color: #fff !important;
}
.top {
    position: absolute;
    background-color: #fff;
    border-radius: 50%;
    background-color: #25bdd4;
    padding: 10px 20px;
    top: -25px;
    left: 2rem;
}
.background_image {
    background-image: url('../../assets/images/contactUs.jpg');
    background-attachment: fixed;
}