
.dropdown_content {
    top: 100%;
    right: 2%;
    background-color: #fff;
    line-height: 1.9;
    text-align: center;
    height: 0px;
    box-shadow: 10px 10px 10px #6bd425;
    background-color: #f0ffe5;
    width: 8rem;
    transition: height 0.2s ease-in-out;
}
.dropdown_button{
    color: #6bd425;
    border: none;
    background-color: transparent !important;
    font-size: 1.3rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
}
.dropdown_text {
    display: none;
    text-decoration: none;
    transition: text-decoration 0.5s ease;
}
.dropdown_text:hover {
    text-decoration: underline;
}
.dropdown:hover .dropdown_content{
    height: 4rem;
}
.dropdown:hover .dropdown_text{
    display: block;
}
 

.navbar_background {
    background-color: #021430;
}
.button {
    color: #6bd425;
    background-color: #fff;
    font-family: 'Mulish';
    padding: 0.4rem 2.2rem;
}
.button:hover {
    color: #6bd425;
    background-color: #fff;
    padding: 0.4rem 2.2rem;
}
.button:active {
    color: #6bd425;
    background-color: #fff;
    padding: 0.4rem 2.2rem;
}