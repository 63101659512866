.primaryBackground {
    background-color: #ecfefe;
    border-radius: 0.5rem;
}
.border {
    border: 1px solid #007bff;
    border-radius: 0.5rem;
    box-shadow: 0 1.8248631954193115px 4.56px #68b8f7;
    margin: 2rem;
    color: #004081;
    padding: 10px;
    text-align: end;
    position: relative;
    background-color: #fff;
}

.icon {
    position: absolute;
    left: 3rem;
    top: -.5rem;
    z-index: 1;
}