@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:ital@1&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:ital@0;1&family=Mulish:ital@1&family=Nova+Square&family=Oswald&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@600;700&display=swap");
body {
  margin: 0;
  line-height: normal;
}

input,
button,
textarea,
select {
  font: inherit;
}
