.homepage_background {
  background-color: #021430;
}
.primaryText {
  color: #6bd425;
  font-weight: bolder !important;
}
.secondary_background {
  background-color: #ecfefe;
  box-shadow: rgb(200, 226, 253) 0px 20px 30px -10px, rgb(190, 218, 249) 30px 20px 30px -10px;;
}
.tertiary_background {
  background-color: #f9ffff;
  }


@media screen and (max-width: "800px") {
  .home_image {
    max-width: 260px;
    margin-top: 1rem;
  }
}
@media screen and  (min-width: "801px") and ( max-width: "920px") {
  .home_image {
    max-width: 600px;
    margin-top: 2rem;
  }
}
.background{
  background-image: url(../../../assets/images/backgroundland.png);
}
