.text {
  color: #000;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: #000;
  }
}

.border {
  padding-bottom: 1rem !important;
  min-height: 5rem;
}

.hr {
  margin-left: 1.5rem;
  width: 70%;
  text-align: center;
  color: #394a77 !important;
  background-color: #394a77 !important;
}
